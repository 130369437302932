<template>
  <div>
    <h1 class="title">Regulation E Disclosures</h1>
    <p class="notification is-warning">
      You must read and accept the following disclosures before continuing.
    </p>
    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="regEAccepted" />
          {{ regeAcknowledgeLine }}
        </label>
      </div>
    </div>
    <article class="message">
      <div class="message-body">
        <p class="content" v-for="(re, index) in regeSplit" :key="index">
          {{ re }}
        </p>
      </div>
    </article>
  </div>
</template>
<script>
import filters from "@/utils/filters";

export default {
  props: ["regeAcknowledgeLine", "rege", "amount", "paymentOption", "action"],
  data() {
    return {
      regEAccepted: false
    };
  },
  computed: {
    regeLabel() {
      if (this.action == "next") {
        if (this.paymentOption == "0" || this.paymentOption == "2") {
          return this.$store.state.rege.rege4Label;
        } else {
          return this.$store.state.rege.rege2Label;
        }
      } else {
        if (this.paymentOption == "0" || this.paymentOption == "2") {
          return this.$store.state.rege.rege3Label;
        } else {
          return this.$store.state.rege.rege1Label;
        }
      }
    },
    regeSplit() {
      let arr = [];
      for (let i = 0; i < this.rege.length; i++) {
        arr.push(this.rege[i].split("\n"));
      }
      return arr.flat();
    }
  },
  watch: {
    regEAccepted(val) {
      if (val) {
        let sodText = this.rege.join();
        let disclosureText =
          sodText +
          "\n" +
          `Payment to be Processed of: ${filters.formatCurrency(this.amount)}`;
        this.$emit("rege-accepted", {
          sdDisclosureLabel: this.regeLabel,
          sdDisclosureText: disclosureText
        });
      }
    }
  }
};
</script>
